<template>
  <div class="lesson">
    <Swiper></Swiper>
    <div class="videoBox">
      <p class="title">{{ video.data.title }}</p>
      <video
        class="mainVideo"
        :src="video.data.video"
        :poster="video.data.img"
        controls
      ></video>
      <div class="right">
        <div
          class="wrap"
          v-for="(item, i) in videoList.data"
          :key="i"
          @click="go1(item.id)"
        >
          <img class="rightVideo" :src="item.img" />
          <p class="sub">
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
    <div class="main">
      <!-- <p class="title">标题标题</p> -->
      <div class="left">
        <!-- <div class="time">2021年一月一日</div> -->
        <div class="content">
          <!-- <p v-html="12312312312313123123123"></p> -->
          <div class="notWatch" v-for="(item, i) in mess.data" :key="i">
            <div class="date">
              <p>{{ item.issue_time.slice(5, 10) }}</p>
              <p>{{ item.issue_time.slice(0, 4) }}</p>
            </div>
            <img class="img" :src="item.img" alt="" />
            <div class="notWatchRight">
              <div class="title2">{{ item.title }}</div>
              <div class="content2">{{ item.resume }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <div class="return">返回</div> -->
        <div>
          <little-message></little-message>
        </div>
      </div>
    </div>
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import LongBox from "./modules/longBoxa.vue";
import MiddleBox from "./modules/middleBox.vue";
import Message from "@/components/message/Message.vue";
import LittleMessage from "./modules/littleMessage.vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@/utils/axios";

// import Tag from "./modules/Tag.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    LongBox,
    MiddleBox,
    Message,
    LittleMessage,
  },
  data() {
    return {
      tabPosition: "left",
      currentPage1: 1,
      currentPage2: 1,
      messList: [
        {
          id: 1,
          mess: "高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 2,
          mess: "2高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
      ],
      demoImg: require("@/assets/1new/关于我们/关于我们.jpg"),
      videoS: require("@/assets/关于我们/djx.mp4"),
    };
  },
  methods: {
    go1(a: any) {
      // console.log(a);
      this.$router.push("/careerLesson/" + a);
      setTimeout(() => {
      location.reload();
      }, 1000);
    },
    goDetail(id: any) {
      // console.log(id);
      this.$router.push({ name: "newsDetail", params: { id: id } });
    },
    handleSizeChange(val: any) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val: any) {
      console.log(`当前页: ${val}`);
    },
    handleSizeChange2(val: any) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange2(val: any) {
      console.log(`当前页: ${val}`);
    },
  },
  setup() {
    // const router = useRouter();
    const route = useRoute();
    const video = reactive({ data: "" });
    const videoList = reactive({ data: "" });
    var mess = reactive({ data: "" });
    axios
      .get(
        "https://www.xsy985.com/api/news/news?page=1&page_size=5&news_type=2"
      )
      .then(function (response) {
        console.log(response.data, "资讯");
        mess.data = response.data.results;
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(route.params, "route");
    axios
      .get("https://www.xsy985.com/api/course/course/" + route.params.id)
      .then(function (response) {
        // console.log(response.data, "video");
        response.data.video = "https://www.xsy985.com/api/course/course/" + route.params.id+"/play_Video/"

        video.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
       // 2
       axios
        .get(
          // "https://www.xsy985.com/api/course/course/?page=1&page_size=2&course_type=2"
        "https://www.xsy985.com/api/course/course/"+route.params.id+"/random_course/",

        )
        .then(function (response) {
          // console.log(response.data,"名师讲堂");
          videoList.data = response.data;
          // console.log(video1.data, "1");
        })
        .catch(function (error) {
          console.log(error);
        });
        // end
    return {
      video,
      mess,
      videoList
    };
  },
});
</script>
<style lang="scss" scoped>
.lesson {
  .videoBox {
    height: 620px;
    width: 1200px;
    position: relative;
  }
  .right {
    width: 360px;
    height: 620px;
    position: absolute;
    right: 0;
    top: 25px;
    .rightVideo {
      width: 360px;
      height: 230px;
    }
    .sub {
      width: 360px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .title {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: -10px;
  }
  .mainVideo {
    width: 810px;
    height: 510px;
      &:focus{
      outline: none;
    }
  }
  // 富文本开始
  .main {
    min-height: 900px;
    position: relative;
    .title {
      color: #00a5a8;
      font-size: 30px;
      margin-bottom: 60px;
    }
    // 左
    .left {
      float: left;
      min-height: 1200px;

      .time {
        color: #99a9bf;
        font-size: 12px;
        width: 800px;
        border-bottom: 2px solid #00a5a8;
        padding-bottom: 10px;
      }
      .content {
        width: 800px;
        min-height: 1200px;
        border-right: 2px solid #999;
        padding-left: 40px;
        padding-top: 30px;
        min-height: 100px;
        .notWatch {
          width: 800px;
          height: 150px;
          margin-bottom: 40px;
          .date {
            font-size: 16px;
            line-height: 20px;
            border-bottom: 1px solid #000;
            width: 88px;
            height: 64px;
            margin-bottom: 4px;
            font-weight: bold;
            float: left;
          }
          .img {
            float: left;
            margin-left: 10px;
            width: 230px;
            height: 150px;
            background-color: pink;
            margin-right: 20px;
          }
          .notWatchRight {
            .title2 {
              font-size: 18px;
              color: #000;
              margin-bottom: 30px;
            }
            .content2 {
              font-size: 14px;
              color: #999;
              line-height: 24px;
            }
          }
        }
      }
    }

    // 右侧推广
    .right {
      float: left;
      margin-left: 20px;
      width: 230px;
      .return {
        width: 230px;
        display: inline-block;
        text-align: right;
        font-size: 22px;
        color: #99a9bf;
      }
    }
  }
}
</style>
